<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('COMMON.SEARCH_OPTIONS')">
          <template v-slot:body>
            <b-row>
              <b-col sm="4" class="my-2">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                  class="mb-0"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </b-col>
              <b-col sm="4" class="my-2">
                <b-form-group
                  :label="$t('LAST_LOGINS.START_DATE')"
                  class="mb-0"
                >
                  <b-form-select
                    v-model="searchForm.startDate.selected"
                    :options="searchForm.startDate.data"
                    value-field="value"
                    text-field="text"
                    @change="setEndDateMin()"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="4" class="my-2">
                <b-form-group :label="$t('LAST_LOGINS.END_DATE')" class="mb-0">
                  <b-form-select
                    v-model="searchForm.endDate.selected"
                    :options="searchForm.endDate.data"
                    value-field="value"
                    text-field="text"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="4" class="my-2">
                <b-form-group
                  :label="$t('LOGS.OUTGOING_LOGS.VIEW_MESSAGES')"
                  label-for="select-viewMsg"
                  class="mb-0"
                >
                  <b-form-select
                    id="select-viewMsg"
                    v-model="searchForm.viewMsg"
                    :options="viewMsgData"
                    value-field="value"
                    text-field="text"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="4" class="my-2">
                <b-form-group
                  :label="$t('LOGS.OUTGOING_LOGS.SENT_FROM')"
                  class="mb-0"
                >
                  <b-form-input
                    v-model="searchForm.sentFrom"
                    :placeholder="$t('LOGS.OUTGOING_LOGS.SENT_FROM')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="4" class="my-2">
                <b-form-group
                  :label="$t('LOGS.OUTGOING_LOGS.SENT_TO')"
                  class="mb-0"
                >
                  <b-form-input
                    v-model="searchForm.sentTo"
                    :placeholder="$t('LOGS.OUTGOING_LOGS.SENT_TO')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="d-flex justify-content-end mt-5">
                <b-button variant="primary" class="mr-4" @click="searchData()">
                  {{ $t("COMMON.SEARCH") }}
                </b-button>
              </b-col>
            </b-row>
          </template>
        </KTPortlet>
        <KTPortlet v-bind:title="$t('COMMON.SEARCH_RESULTS')">
          <template v-slot:toolbar>
            <download-csv :data="outgoingLogs">
              <b-button variant="success" class="mr-4">
                {{ $t("USERS.EXPORT_RESULTS") }}
              </b-button>
            </download-csv>
          </template>
          <template v-slot:body>
            <p>{{ $t("LOGS.OUTGOING_LOGS.DESC") }}</p>
            <b-row>
              <b-col
                lg="12"
                v-for="(item, index) in outgoingLogs"
                v-bind:key="index"
              >
                <b-card
                  :header="item.mdate2 + ' - ' + item.messagesubject"
                  class="font14"
                >
                  <b-card-text>
                    <b>{{ $t("LOGS.OUTGOING_LOGS.SENT_FROM") }}: </b>
                    {{ item.sendfrom }}
                  </b-card-text>
                  <b-card-text>
                    <b>{{ $t("LOGS.OUTGOING_LOGS.SENDER_IP") }}: </b>
                    {{ item.sendip }}
                  </b-card-text>
                  <b-card-text>
                    <b>{{ $t("LOGS.OUTGOING_LOGS.SENT_TO") }}: </b>
                    {{ item.recipient }}
                  </b-card-text>
                  <b-card-text>
                    <b>{{ $t("LOGS.OUTGOING_LOGS.RECIPIENT_STATUS") }}: </b>
                    {{ item.rmsg }}
                  </b-card-text>
                  <b-card-text>
                    <b>{{ $t("LOGS.OUTGOING_LOGS.MESSAGE_STATUS") }}: </b>
                    {{ item.returndata }}
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import moment from "moment";

export default {
  name: "outgoinglogs",
  components: {
    KTPortlet,
  },
  data: () => ({
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    searchForm: {
      startDate: {
        selected: "",
        data: [],
      },
      endDate: {
        selected: "",
        data: [],
      },
      sentFrom: "",
      sentTo: "",
      viewMsg: 1,
    },
    outgoingLogs: [],
  }),
  computed: {
    viewMsgData: function () {
      return [
        { text: this.$t("LOGS.OUTGOING_LOGS.DELIVERED"), value: 1 },
        { text: this.$t("LOGS.OUTGOING_LOGS.TEMPORARILY_REJECTED"), value: 2 },
        { text: this.$t("LOGS.OUTGOING_LOGS.PERMANENTLY_REJECTED"), value: 3 },
        { text: this.$t("LOGS.OUTGOING_LOGS.ALL_MESSAGES"), value: 4 },
      ];
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
      includeAliasDomains: 1,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
      }
    });
    postRequest({
      action: "getAdminDateFormat",
      token: localStorage.id_token,
    }).then((res) => {
      if (res.returncode) {
        const datetype = res.returndata.dateformat.toUpperCase();
        const todayDate = res.returndata.today;
        const dateArray = [];
        for (let i = 29; i >= 0; i--) {
          let newDate = todayDate - 3600 * 24 * i;
          const eachDay = moment(newDate * 1000).format(datetype);
          dateArray.push({
            value: newDate,
            text: eachDay,
          });
        }

        this.searchForm.startDate.selected = todayDate;
        this.searchForm.startDate.data = dateArray;
        this.searchForm.endDate.selected = todayDate;
        this.searchForm.endDate.data = dateArray;
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.OUTGOING_LOGS") },
    ]);
  },
  methods: {
    /*  -----modal close------- */
    confirmModalClose(modalRef) {
      this.$refs[modalRef].hide();
    },

    searchData() {
      this.outgoingLogs = [];
      postRequest({
        action: "getDomainRemoteDeliveries",
        token: localStorage.id_token,
        domain: this.selectDomain.selectedDomain.domain,
        mcode: this.searchForm.viewMsg,
        startdate: this.searchForm.startDate.selected,
        enddate: this.searchForm.endDate.selected,
        sender: this.searchForm.sentFrom,
        recipient: this.searchForm.sentTo,
      }).then((res) => {
        if (res && res.returncode) {
          this.outgoingLogs = res.returndata;
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    setEndDateMin() {
      const dateArray = this.searchForm.startDate.data;
      const startDate = this.searchForm.startDate.selected;
      const dateLength = dateArray.length;
      const selected = this.searchForm.endDate.selected;
      let endDateArray = [];
      for (let i = 0; i < dateLength; i++) {
        if (startDate == dateArray[i].value) {
          endDateArray = dateArray.slice(i, dateLength);
          break;
        }
      }
      this.searchForm.endDate.data = endDateArray;
      this.searchForm.endDate.selected =
        selected > endDateArray[0].value ? selected : endDateArray[0].value;
    },
    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>
